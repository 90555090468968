<template>
  <div class="registrationFee-list">
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">校车管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">报名缴费管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="选择线路" prop="routeId" label-width="80px">
        <el-select v-model="queryModel.routeId" filterable placeholder="请选择" size="mini" clearable>
          <el-option v-for="item in routeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="学年度" prop="periodId" filterable label-width="80px">
        <el-select v-model="queryModel.periodId" placeholder="请选择" size="mini" clearable>
          <el-option v-for="item in periodList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="学生姓名" prop="name" label-width="80px">
        <el-input v-model="queryModel.name" size="mini" />
      </el-form-item>
      <el-form-item label="性别" prop="sex" label-width="80px">
        <el-select v-model="queryModel.sex" placeholder="请选择" size="mini" clearable>
          <el-option v-for="item in sexList" :key="item.id" :label="item.lable" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="学校/班级" prop="schoolId" label-width="80px">
        <!-- <el-cascader
          v-model="queryModel.schoolId"
          style="width:350px"
          placeholder="请选择" 
          size="mini"
          :options="schoolClassList"
          change-on-select
        ></el-cascader> -->
        <el-select-tree
          size="mini"
          :options="schoolClassList"
          v-model="queryModel.schoolId"
          height="200"
        ></el-select-tree>
      </el-form-item>
      <el-form-item label="报名日期" prop="orderCreateDate" label-width="80px">
        <template>
          <div class="block" size="mini">
            <el-date-picker
              size="mini"
              v-model="queryModel.orderCreateDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </div>
        </template>
      </el-form-item>
      <el-form-item label="缴费状态" prop="payStatus" label-width="80px">
        <el-select v-model="queryModel.payStatus" placeholder="请选择" size="mini" clearable>
          <el-option
            v-for="item in payStatusList"
            :key="item.id"
            :label="item.lable"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="缴费日期" prop="payTime" label-width="80px">
        <template>
          <div class="block">
            <el-date-picker
              size="mini"
              v-model="queryModel.payTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </div>
        </template>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-download"
        :loading="downloadLoading"
        @click="downloadXls"
      >导出数据</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-coin"
        :disabled="multipleSelection.length==0"
        @click="handleBatchUpdate"
      >线下付款
      </el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-remove"
        :disabled="multipleSelection.length==0"
        @click="handleBatchDelete"
      >删除
      </el-button>
    </el-row>
    <el-table
      ref="formTable"
      :data="tableData"
      v-loading="loading"
      element-loading-text="拼命加载中"
      stripe
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="routeName" label="线路名称" fixed="left"></el-table-column>
      <el-table-column prop="stationName" label="站点名称" fixed="left"></el-table-column>
      <el-table-column prop="periodName" label="学年度" fixed="left"></el-table-column>
      <el-table-column prop="name" label="学生姓名" fixed="left"></el-table-column>
      <el-table-column prop="sex" label="性别" fixed="left" width="80"></el-table-column>
      <el-table-column prop="school" label="学校" fixed="left"></el-table-column>
      <el-table-column prop="className" label="班级" fixed="left"></el-table-column>
      <el-table-column prop="phone" label="联系方式" fixed="left"></el-table-column>
      <el-table-column prop="orderCreateDate" label="报名日期" fixed="left" width="140"></el-table-column>
      <el-table-column prop="payNameN" label="缴费方式" fixed="left"></el-table-column>
      <el-table-column prop="payTime" label="缴费时间" fixed="left" width="140">
        <template slot-scope="{row}">
          <el-button v-if="row.payTime ==null" size="mini" type="primary" @click="handleWrite(row)">备注时间</el-button>
          <span v-else>{{row.payTime}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="payStatus" label="缴费状态" fixed="left"></el-table-column>
    </el-table>
    <div style="text-align:center;">
      <el-pagination
        :current-page.sync="pageIndex"
        :total="totalElements"
        :page-sizes="pageSizeList"
        @current-change="changePage"
        @size-change="pageSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
    <registrationFee-detail
        v-if="showModal"
        :businessKey="businessKey"
        :parentId="selectedRecord.id"
        :title="modalTitle"
        @close="onDetailModalClose"
    ></registrationFee-detail>
  </div>
</template>
<script>
import registrationFeeApi from "@/api/bus/registrationFee";
import routeApi from "@/api/bus/route";
import registrationFeeDetail from "./registrationFee-detail";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";

export default {
  name: "busRegistrationFeeList",
  data() {
    return {
      queryModel: {
        routeId: "",
        periodId: "",
        name: "",
        sex: "",
        schoolId: "",
        classId: "",
        orderCreateDate: "",
        payTime: "",
        payStatus: ""
      },
      showModal: false,
      downloadLoading: false,
      routeList: [],
      periodList: [],
      schoolClassList: [],
      sexList: [
        {
          lable: "男",
          value: "1"
        },
        {
          lable: "女",
          value: "2"
        }
      ],
      payStatusList: [
        {
          lable: "未缴费",
          value: "10"
        },
        {
          lable: "部分支付",
          value: "15"
        },
                {
          lable: "支付完成",
          value: "20"
        },
        {
          lable: "已关闭",
          value: "30"
        },
        {
          lable: "已退款",
          value: "40"
        }
      ],
      loading: false,
      tableData: [],
      multipleSelection: [],
      pageIndex: 1,
      pageSize: 10,
      totalElements: 0,
      pageSizeList: [10, 20, 30]
    };
  },
  created() {
    var self = this;
    routeApi.routeList().then(response => {
      var jsonData = response.data;
      this.routeList = jsonData.data;
    });

    registrationFeeApi.periodList().then(response => {
      var jsonData = response.data;
      this.periodList = jsonData.data;
    });

    // registrationFeeApi.schoolClassList().then(response => {
    //   var jsonData = response.data;
    //   this.schoolClassList = jsonData.data;
    // });
    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;

      if (jsonData.result) {
        self.schoolClassList = jsonData.data;
      }
    });
  },
  methods: {
    changePage(pageIndex) {
      this.loading = true;
      var formData = new FormData();

      formData.append("pageIndex", this.pageIndex);
      formData.append("pageSize", this.pageSize);
      formData.append("routeId", this.queryModel.routeId);
      formData.append("periodId", this.queryModel.periodId);
      formData.append("name", this.queryModel.name);
      formData.append("sex", this.queryModel.sex);
      formData.append("payStatus", this.queryModel.payStatus);
      formData.append("orderCreateDateRanges", this.queryModel.orderCreateDate);
      formData.append("payTimeRanges", this.queryModel.payTime);
      formData.append("schoolClassId", this.queryModel.schoolId);

      registrationFeeApi.pageList(formData).then(response => {
        this.loading = false;
        var jsonData = response.data;

        console.log(jsonData);

        this.tableData = jsonData.data.data;
        this.totalElements = jsonData.data.recordsTotal;

        this.pageIndex = pageIndex;
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    downloadXls() {
      //导出
      var self = this;
      self.downloadLoading = true;
      self.loading = true;
      self.loadingText = "数据导出中，请稍后...";

      var formData = new FormData();

      formData.append("routeId", this.queryModel.routeId);
      formData.append("periodId", this.queryModel.periodId);
      formData.append("name", this.queryModel.name);
      formData.append("sex", this.queryModel.sex);
      formData.append("payStatus", this.queryModel.payStatus);
      formData.append("orderCreateDateRanges", this.queryModel.orderCreateDate);
      formData.append("payTimeRanges", this.queryModel.payTime);
      formData.append("schoolClassId", this.queryModel.schoolId);

      var timestamp = new Date().getTime();
      formData.append("timestamp", timestamp);

      formData.append("pageSize", 2000);

      registrationFeeApi.exportXls(formData).then(function(response) {
        var jsonData = response.data;
        self.downloadLoading = false;
        self.loading = false;
        if (jsonData.result) {
          //下载有错误信息提示的报表
          self.$message({
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
            duration: 60000,
            type: "success"
          });
        } else {
          self.$message.error(jsonData.message);
        }
      });
    },
    handleWrite(record){
      this.modalTitle = "填写时间";
      this.operation = "edit";
      this.businessKey = record.id;
      this.selectedRecord = record;
      this.showModal = true;
    },
    onDetailModalClose(retObj) {
        //保存成功后回调
        this.showModal = false;

        if (retObj.result) {
            var retData = retObj.data;
            this.changePage(this.pageIndex);
        }
    },
    handleSelectionChange(val) {
        this.multipleSelection = val;
    },
    handleBatchUpdate() {
        var self = this;

        var idList = this.multipleSelection
                    .filter(record=>record.payTime==null)
                    .map(record => {
                        return record.id;
                    });

        this.$confirm("是否确认将选中项设为线下付款？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(() => {
            registrationFeeApi.batchUpdate(idList).then(function (response) {
                var jsonData = response.data;

                if (jsonData.result) {
                    self.changePage(self.pageIndex);

                    self.$message({
                        type: "success",
                        message: "设置成功!"
                    });
                }
            });
        });
    },
    handleBatchDelete() {
        var self = this;

        var idList = this.multipleSelection
                    .filter(record=>record.payStatus=='已退款'||record.payStatus=='未缴费')
                    .map(record => {
                        return record.id;
                    });

        if(idList.length==0){
          this.$message.warning("只能勾选已退款和未缴费的记录!");
          return;
        }

        this.$confirm("是否确认将选中项删除？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(() => {
            registrationFeeApi.batchDelete(idList).then(function (response) {
                var jsonData = response.data;

                if (jsonData.result) {
                    self.changePage(self.pageIndex);

                    self.$message({
                        type: "success",
                        message: "删除成功!"
                    });
                }
            });
        });
    }
  },
  components: {
    "registrationFee-detail": registrationFeeDetail,
    "el-select-tree": SelectTree
  },
  mounted() {
    this.changePage(1);
  },

};
</script>
<style scoped>
.el-divider {
  margin: 5px 0;
}

.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.registrationFee-list {
  text-align: left;
}
.el-select-dropdown__item {
  margin-left: 5px;
}
</style>