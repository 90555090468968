import { render, staticRenderFns } from "./registrationFee-detail.vue?vue&type=template&id=7eefa4e0&scoped=true&"
import script from "./registrationFee-detail.vue?vue&type=script&lang=js&"
export * from "./registrationFee-detail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7eefa4e0",
  null
  
)

export default component.exports