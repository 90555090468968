<template>
    <div>
        <el-dialog
        width="30%"
        :visible.sync="showDialog"
        :title="title"
        :modal-append-to-body="true"
        :append-to-body="true"
        style="text-align:left;"
        @close="closeDialog"
        :close-on-click-modal="false"
        >
            <div class="user-panel" v-loading="loading">
                <el-form ref="form" :model="formModel" :rules="ruleValidate">
                    <el-form-item label="缴费方式" prop="payName">
                        <el-select v-model="formModel.payName" placeholder="请选择缴费方式" style="width:300px">
                            <el-option
                                v-for="item in payNameList"
                                :key="item.value"
                                :label="item.lable"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="缴费时间" prop="payTime">
                        <template>
                            <div class="block">
                                <el-date-picker
                                style="width:300px"
                                v-model="formModel.payTime"
                                type="date"
                                placeholder="选择缴费时间"
                                value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </div>
                        </template>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="handleSubmit"  :loading="submitting">确 定</el-button>
            </span>
        </el-dialog>  
    </div>
</template>
<script>
import Constant from "@/constant";
import registrationFeeApi from "@/api/bus/registrationFee";
export default {
    props: ["businessKey", "title"],
    data() {
        return{
            formModel:{
                id: "",
                payName:"",
                payTime:"",
            },
            payNameList:[{
                lable:"微信",
                value:"wechat"
            },
            {
                lable:"支付宝",
                value:"alipay"
            },
            {
                lable:"现金",
                value:"cash"
            }],
            showDialog: true,
            loading: false,
            submitting: false,
            ruleValidate: {
                payName: [{ required: true, message: "缴费方式不能为空", trigger: "blur" }],
                payTime: [{ required: true, message: "缴费时间不能为空", trigger: "blur" }]
            },
        }
    },
    methods: {
        closeDialog() {
            this.$emit("close", false);
        },
        handleSubmit() {
            var self = this;
            this.$refs["form"].validate(valid => {
                if (valid) {
                (function() {
                    var id = self.formModel.id;
                    var payName = self.formModel.payName;
                    var payTime = self.formModel.payTime;

                    var formData = new FormData();
                    formData.append("id", id);
                    formData.append("payName", payName);
                    formData.append("payTime", payTime);

                    return registrationFeeApi.update(formData);
                    
                })().then(function(response) {
                    var jsonData = response.data;

                    if (jsonData.result) {
                    self.$message({
                        message: "保存成功!",
                        type: "success"
                    });
                    self.$emit("close", {
                        result: true,
                        data: jsonData.data
                    });
                    } else {
                    self.$message({
                        message: jsonData.message + "",
                        type: "warning"
                    });

                    self.$emit("close", {
                        result: false
                    });
                    }
                });
                }
            });
        },
    },
    async mounted() {
        console.log("mounted");

        var self = this;

        (function() {
            return registrationFeeApi.edit(self.businessKey);
        })()
        .then(response => {
            var jsonData = response.data;

            if (jsonData.result) {
            self.formModel = jsonData.data;
            } else {
            self.$message.error(jsonData.message + "");
            }
        })
        .catch(error => {
            self.$message.error(error + "");
        });
    }
}
</script>
<style scoped>

</style>