import { render, staticRenderFns } from "./registrationFee-list.vue?vue&type=template&id=60e1ca9a&scoped=true&"
import script from "./registrationFee-list.vue?vue&type=script&lang=js&"
export * from "./registrationFee-list.vue?vue&type=script&lang=js&"
import style0 from "./registrationFee-list.vue?vue&type=style&index=0&id=60e1ca9a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60e1ca9a",
  null
  
)

export default component.exports